.quiz-contain {
    overflow: hidden;
    padding: 0;

    form {
        height: 100%;
    }

    .intro-text {
        padding-top: 0;

        &::before {
            display: none;
        }
    }

    .block-content {
        margin-bottom: 0;

        p {
            margin-bottom: 20px;
        }
    }

    @media (--tablet) {
        width: 768px;
    }

    @media (--until-tablet) {
        width: 100%;

        .intro-text {
            float: none;
            clear: both;
            max-width: 100%;
        }

        .block-content {
            width: 100%;
            float: none;
        }
    }

    @media (--mobile) {
        width: 100%;

        .intro-text {
            float: none;
            clear: both;
            max-width: 100%;
        }

        .block-content {
            width: 100%;
            float: none;
        }
    }
}
