.quiz-checkbox,
.quiz-radio {
    flex: 0 1 310px;
    width: 310px;
    position: relative;
    margin-right: 15px;
    margin-bottom: 20px;
    border-radius: 20px;

    &:nth-child(3n + 3) {
        margin-right: 0;
    }

    > label {
        position: realtive;
        padding-left: 60px;
        cursor: pointer;
        margin-bottom: 0;
        display: block;
        font-size: var(--font-size);
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-height: 40px;

        span {
            display: block;
            line-height: 1.3;
        }

        &::before,
        &::after {
            display: block;
            position: absolute;
        }

        &::before {
            content: '';
            background-color: rgba(0,0,0,.5);
            width: 40px;
            height: 40px;
            transition: .5s border-color ease;
            left: 0;
            top: 0;
            border-radius: 20px;
            transition: .5s background-color ease;
        }

        &::after {
            content: '';
            color: #000;
            opacity: 0;
            overflow: hidden;
            text-align: center;
            transition: .5s width ease, .5s height ease, .5s opacity ease;
            left: 0;
            top: 0;
            width: 40px;
            height: 40px;
            border-radius: 20px;
            line-height: 40px;
        }
    }

    [type='checkbox'],
    [type='radio'] {
        width: 40px;
        height: 40px;
        overflow: hidden;
        appearance: none;
        border: 0;
        background-color: transparent;
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 20px;
        opacity: 0;

        &:focus {
            outline: 0;

            + label {
                color: var(--orange);

                span {
                    color: var(--orange);
                }
            }
        }

        &:checked {
            outline: 0;

            + label {
                &::before {
                    background-color: #fff;
                }

                &::after {
                    opacity: 1;
                    content: '✔';
                }
            }
        }
    }

    @media (--tablet) {
        flex: 0 1 344px;
        width: 344px;

        &:nth-child(3n + 3) {
            margin-right: 15px;
        }

        &:nth-child(2n + 2) {
            margin-right: 0;
        }
    }

    @media (--until-tablet) {
        flex: 0 0 100%;
        width: 100%;
        margin-right: 0;
        height: 40px;
    }
}

.quiz-radio {
    height: 40px;

    label {
        line-height: 40px;
    }

    input {
        line-height: 0;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
        border-radius: 0;
        background-color: transparent;
    }
}
