ul.icon-list {
    list-style: none;
    padding: 0;

    h3 {
        font-size: var(--font-size-h3);
        margin-bottom: 10px;
    }

    > li {
        padding-right: 140px;
        min-height: 140px;
        background-repeat: no-repeat;
        background-size: 110px auto;
        background-position: 100% 0;
        padding-bottom: 30px;
        margin-bottom: 30px;

        &:not(:last-child) {
            border-bottom: 1px solid rgba(255,255,255,.2);
        }

        &:last-child,
        :last-child {
            margin: 0;
            padding-bottom: 0;
        }

        p:last-child {
            margin-bottom: 0;
        }
    }

    ul {
        padding: 0 0 0 24px;
        margin: 0 0 20px;
        list-style: square;

        li {
            margin-bottom: 15px;
        }
    }

    + hr {
        margin-top: 60px;
        margin-bottom: 60px;
    }

    @media (--tablet) {
        > li {
            background-size: 80px auto;
            padding-right: 90px;
        }
    }

    @media (--until-tablet) {
        h3 {
            font-size: 18px;
            margin-bottom: 20px;
        }

        > li {
            padding-right: 60px;
            min-height: 60px;
            background-size: 55px auto;
        }
    }
}
