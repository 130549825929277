.site-footer {
    height: 70px;
    background-color: rgba(0,0,0,.6);
    color: #fff;

    p {
        float: left;
        line-height: 70px;
        margin: 0;
    }

    @media (--until-tablet) {
        height: auto;
        padding-top: 20px;
        padding-bottom: 20px;

        p {
            line-height: 1;
            margin-bottom: 15px;
            text-align: center;
            float: none;
            font-size: 14px;
        }
    }
}

.footer-nav {
    float: right;

    ul {
        list-style: none;
    }

    li {
        display: inline-block;

        &:not(:last-child) {
            padding-right: 10px;
            margin-right: 10px;
            position: relative;

            &::after {
                content: '';
                display: block;
                width: 3px;
                height: 3px;
                background-color: rgba(255,255,255,.5);
                position: absolute;
                right: -2px;
                top: 50%;
                margin-top: -1px;
            }
        }
    }

    a {
        color: #fff;
        text-decoration: none;
        line-height: 70px;
        transition: .5s color ease;

        &:hover {
            color: rgba(255,255,255,.6);
        }
    }

    @media (--until-tablet) {
        float: none;
        text-align: center;

        a {
            line-height: 1.2;
            font-size: 14px;
        }
    }
}
