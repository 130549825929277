.ward,
.salon,
.leisure {

    color: var(--text-dark);

    .intro-text::before {
        background-color: var(--text-dark);
    }

    hr {
        background-color: var(--text-dark);
    }

    main & {

        a {
            color: var(--text-dark);
        }
    }

    .footer-nav a {
        color: #fff;

        &:hover {
            color: rgba(255,255,255,.6);
        }
    }

    .icon-list > li {
        &:not(:last-child) {
            border-bottom: 1px solid rgba(0,0,0,.2);
        }
    }

    .tab-group-button {
        color: var(--text-dark);
        background: linear-gradient(to right, rgba(0,0,0,.01) 0%, rgba(0,0,0,.1) 100%);

        &::after {
            color: var(--text-dark);
        }

        &:hover {
            background: linear-gradient(to right, rgba(0,0,0,.01) 0%, rgba(0,0,0,.2) 100%);

            &::after {
                border-left-color: rgba(0,0,0,.2);
            }
        }

        &.active,
        &:focus {
            background: linear-gradient(to right, rgba(0,0,0,.01) 0%, rgba(0,0,0,.3) 100%);

            &::after {
                border-left-color: rgba(0,0,0,.3);
            }
        }
    }
}
