.survey {
    .question__contain--multiple {
        margin-top: 10px;
        margin-bottom: 20px;

        .question__contain:first-child {
            margin-right: 40px;
        }
    }

    .question__contain--parent {
        margin-top: 55px;
    }
}
