@media print {
    header {
        display: none;
    }

    body {
        /* stylelint-disable declaration-no-important  */
        background: #fff !important;
        /* stylelint-enable */

        color: var(--text-dark);

        .bg-workplace-risks-top,
        .bg-workplace-risks-bottom {
            background: none;
        }

        .intro-text::before {
            background-color: var(--text-dark);
        }

        hr {
            background-color: var(--text-dark);
        }

        a {
            color: var(--text-dark);
        }

        .icon-list li {
            &:not(:last-child) {
                border-bottom: 1px solid rgba(0,0,0,.2);
            }
        }

        .tab-group-buttons {
            display: none;
        }

        .tab-group-text {
            display: block;
            /* stylelint-disable declaration-no-important  */
            visibility: visible !important;
            /* stylelint-enable */
            margin-bottom: 20px;

            .section-title {
                display: block;
                font-size: 24px;
            }
        }
    }

    footer {
        display: none;
    }

    .quiz-contain {
        height: auto;
    }

    .quiz-slide {
        position: static;
        left: auto;
        padding-bottom: 32px;
    }

    .quiz-contain .intro-text,
    .quiz-contain .block-content {
        float: none;
        clear: both;
        width: 100%;
        max-width: 100%;
    }

    .quiz-contain .block-content {
        margin-top: 32px;
    }

    .question-progress {
        font-size: 34px;
        line-height: 44px;
    }

    .progress-meter {
        display: none;
    }

    .button.button-short {
        text-align: left;
        padding: 0;
        border: 0;
        border-radius: 0;
        width: auto;
        text-transform: none;

        &::after {
            content: 'http://elf-occupational-health.rocketscience.modxcloud.com' attr(href);
            color: #5c10a9;
            display: block;
            clear: both;
        }
    }

    .quiz-slide .intro-text > span,
    .slide-title > span,
    .condition,
    .better-when,
    .age {
        color: #5c10a9;
    }
}
