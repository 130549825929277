.slide {
    width: 1024px;
    padding-bottom: 120px;
    padding-left: 32px;
    padding-right: 32px;
    /* position: absolute; */
    /* left: 0; */
    display: block;
    transition: 1s opacity ease, 1s transform ease;
    transform: translate(0,0) translateZ(0);
    transform-origin: 50% 0;
    opacity: 1;
    backface-visibility: hidden;
    will-change: transform;

    &.enter {
        transform: translate(0,-100%) translateZ(0);
        opacity: 0;
    }

    &.leave {
        transform: translate(0,66%) translateZ(0);
        opacity: 0;
    }

    @media (--tablet) {
        width: 768px;
        height: 100%;
        padding-left: 20px;
        padding-right: 20px;
    }

    @media (--until-tablet) {
        width: 100%;
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 40px;
    }
}

.standout-information {
    background-color: rgba(0,0,0,.5);
    border-radius: 6px;
    margin-bottom: 20px;
    clear: both;
    padding: 15px 20px;
}

.quiz-slide .intro-text > span,
.slide-title > span,
.condition,
.better-when,
.age {
    font-weight: bold;
    color: var(--orange);
    display: inline;
}

.log-results {
    position: absolute;
    top: 80px;
    left: 80px;
    right: 80px;
    bottom: 80px;
    background-color: #fff;
    color: #000;
    padding: 20px 40px;
    overflow: scroll;
    display: none;
}
