hr {
    width: 100%;
    height: 5px;
    border-radius: 2px;
    background-color: #fff;
}

section {
    clear: fix;
}

article {
    padding-top: 50px;

    &:last-of-type {
        padding-bottom: 100px;

        .landing & {
            padding-bottom: 0;
        }
    }

    > h2 {
        float: left;
        clear: left;
    }

    ul {
        padding: 0 0 0 24px;
        margin: 0 0 20px;
        list-style: square;

        > li {
            margin-bottom: 10px;
        }
    }

    a {
        color: var(--orange);
        transition: .5s opacity ease;

        &:hover,
        &:focus {
            opacity: .5;
        }
    }

    @media (--tablet) {
        &:last-of-type {
            padding-bottom: 40px;
        }
    }

    @media (--until-tablet) {
        padding-top: 38px;

        &:last-of-type {
            padding-bottom: 38px;

            .landing & {
                padding-bottom: 0;
            }
        }
    }
}

.pre-heading {
    text-transform: uppercase;
    font-size: 18px;
    line-height: calc(25 / 18 * 100%);
    margin-bottom: 10px;
}

.intro-text {
    font-size: 18px;
    font-weight: bold;
    line-height: calc(27 / 18 * 100%);
    padding-top: 30px;
    position: relative;
    float: left;
    clear: left;
    max-width: calc(340 / 960 * 100%);

    &::before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 90px;
        height: 5px;
        border-radius: 2px;
        background-color: #fff;
    }

    @media (--until-tablet) {
        max-width: 100%;
        float: none;
        clear: both;
        font-size: 16px;
        line-height: 145%;
    }
}

.block-content {
    float: right;
    width: calc(544 / 960 * 100%);
    margin-bottom: 70px;

    &--padded {
        padding-top: 80px;
    }

    > h2 {
        max-width: 100%;
    }

    .bg-landing-top & {
        margin-top: 31px;
    }

    .quiz-intro &,
    .factsheet & {
        padding-top: 30px;
    }

    .quiz-intro &,
    .landing &,
    .factsheet & {
        padding-bottom: 50px;
    }

    &:last-of-type {
        margin-bottom: 0;
    }

    @media (--tablet) {
        .quiz-intro &,
        .landing &,
        .factsheet & {
            padding-bottom: 30px;
        }
    }

    @media (--until-tablet) {
        width: 100%;
        float: none;
        clear: both;
        margin-bottom: 40px;

        .quiz-intro & {
            padding-top: 0;
        }

        .quiz-intro &,
        .landing &,
        .factsheet & {
            padding-bottom: 38px;
        }
    }
}

.get-help {
    padding: 30px;
    background-color: rgba(255,255,255,.1);
    border-radius: 6px;
    margin-top: 60px;
    margin-bottom: 60px;
    clear: fix;

    h2 {
        font-size: var(--font-size-h3);
        margin-bottom: 10px;
    }

    p {
        float: left;
        clear: left;
        width: calc(100% - 300px);
    }

    .button {
        float: right;
        clear: right;
    }

    @media (--until-tablet) {
        padding: 20px;
        margin-top: 40px;
        margin-bottom: 40px;

        p {
            float: none;
            clear: both;
            width: 100%;
        }

        .button {
            float: none;
            clear: both;
        }
    }
}

.intro {
    margin-bottom: 25px;
    min-height: 300px;

    h1 {
        margin-bottom: 10px;
        max-width: 42%;
    }

    .intro-text {
        float: none;
        padding-top: 0;

        &::before {
            display: none;
        }
    }

    &--col {
        float: left;
        width: 40%;
        clear: left;

        h1,
        h2,
        .intro-text {
            max-width: 100%;
        }
    }

    @media (--until-tablet) {
        min-height: 100px;

        h1 {
            max-width: 100%;
        }
    }
}
