.question__smallprint {
    display: block;
    float: left;
    clear: left;
    margin-top: 20px;
    margin-bottom: 40px;
    line-height: 1.2;
    font-size: 80%;
    max-width: calc(580 / 960 * 100%);
    text-shadow: 1px 1px 2px rgba(0,0,0,.6);
}

.question__title {
    display: block;
    clear: both;
    flex: 0 0 100%;
}

.question--multiSelectWithDetail,
.question--singleSelectWithDetail,
.question--occupationAutocomplete,
.question--multiSelect,
.question--singleSelect,
.question__contain--parent {
    margin-top: 40px;
    margin-bottom: 40px;
    clear: both;
    display: flex;
    flex-wrap: wrap;

    &:only-child,
    &:first-child {
        margin-top: 0;
    }

    @media (--until-desktop) {
        margin-top: 20px;
        margin-bottom: 20px;
    }
}

.question--occupationAutocomplete {
    .question__contain {
        width: 400px;
        max-width: 100%;
    }
}

.question__contain {
    margin-bottom: 20px;

    &--textarea {
        width: 100%;
        margin-top: 20px;
    }
}

.question__contain--parent {
    clear: both;
    margin-top: 20px;
}

.question__contain--multiple {
    clear: fix;
}

.question__contain--number,
.question__contain--select {
    float: left;
}

.question__contain--number {
    width: 62px;
    padding: 0;
}

.question__contain--select {
    width: 100%;
    max-width: 320px;
    position: relative;
    border-radius: 2px;
    padding: 0;

    &::before,
    &::after {
        content: '';
        display: block;
        position: absolute;
        pointer-events: none;

        top: auto;
    }

    &::before {
        bottom: 2px;
        right: 2px;
        width: 35px;
        height: 46px;
        background-color: #fff;
    }

    &::after {
        bottom: 20px;
        right: 13px;
        width: 0;
        height: 0;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-top: 10px solid #000;
    }
}

.question__contain--radio,
.question__contain--checkbox {
    flex: 0 1 calc(450 / 960 * 100%);
    width: calc(450 / 960 * 100%);
    position: relative;
    margin-right: 15px;
    margin-top: 20px;
    border-radius: 20px;
    clear: both;

    &:nth-child(even) {
        margin-right: 0;
    }

    @media (--tablet) {
        flex: 0 1 344px;
        width: 344px;
    }

    @media (--until-tablet) {
        flex: 0 0 100%;
        width: 100%;
        margin-right: 0;
    }
}

.question__contain--radio + .question__contain--textarea {
    margin-top: 40px;
}

.question__label--radio,
.question__label--checkbox {
    position: relative;
    padding-left: 60px;
    cursor: pointer;
    margin-bottom: 0;
    font-size: var(--font-size);
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 40px;

    &::before,
    &::after {
        display: block;
        position: absolute;
    }

    &::before {
        content: '';
        background-color: rgba(0,0,0,.5);
        width: 40px;
        height: 40px;
        transition: .5s border-color ease;
        left: 0;
        top: 0;
        border-radius: 20px;
        transition: .5s background-color ease;
    }

    &::after {
        content: '';
        color: #000;
        opacity: 0;
        overflow: hidden;
        text-align: center;
        transition: .5s width ease, .5s height ease, .5s opacity ease;
        left: 0;
        top: 0;
        width: 40px;
        height: 40px;
        border-radius: 20px;
        line-height: 40px;
    }

    .ar & {
        padding-left: 0;
        padding-right: 60px;

        &::before,
        &::after {
            left: initial;
            right: 0;
        }
    }
}

.quesstion__label--radio {
    line-height: 40px;
}

.question__value--radio,
.question__value--checkbox {
    display: block;
    line-height: 1.3;
}

.question__input--number {
    padding: 0;
    text-align: center;
    appearance: textfield;
}

.question__input--select {
    appearance: textfield;
    background: #fff;
    border: 0;
    width: 100%;
    height: 50px;
    line-height: 50px;
    border-radius: 2px;
    padding: 0 30px 0 20px;
    border: 2px solid #fff;

    &:focus {
        outline: 0;
        border: 2px solid #000;
    }
}

::-ms-expand {
    display: none;
}

.question__input--radio,
.question__input--checkbox {
    width: 40px;
    height: 40px;
    overflow: hidden;
    appearance: none;
    border: 0;
    background-color: transparent;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 20px;
    opacity: 0;

    &:focus {
        outline: 0;

        + .question__label--radio,
        + .question__label--checkbox {
            color: var(--orange);
        }

        + .question__label--radio .question__value--radio,
        + .question__label--checkbox .question__value--checkbox {
            color: var(--orange);
        }
    }

    &:checked {
        outline: 0;

        + .question__label--radio,
        + .question__label--checkbox {
            &::before {
                background-color: #fff;
            }

            &::after {
                opacity: 1;
                content: '✔';
            }
        }
    }
}

.question__input--radio {
    line-height: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    border-radius: 0;
    background-color: transparent;
}

.question__input--textarea {
    width: calc(690 / 960 * 100%);
    border: 2px solid #fff;
    background-color: #fff;
    height: 116px;
    border-radius: 2px;
    padding: 15px 20px;
    transition: .5s color ease, .5s border ease;

    &::placeholder {
        color: #000;
        transition: .5s color ease;
    }

    &:focus {
        outline: 0;
        border: 2px solid #000;

        &::placeholder {
            color: #fff;
        }
    }

    @media (--until-tablet) {
        width: 100%;
    }
}

.question__button,
.question__proceed {
    appearance: none;
    display: block;
    transition: .25s background-color ease;

    &:focus {
        outline: 0;
    }
}

.question__proceed {
    line-height: 40px;
    border-radius: 20px;
    height: 40px;
    background-color: #fff;
    color: var(--grey);
    text-transform: uppercase;
    font-weight: bold;
    width: 240px;
    text-align: center;
    padding: 0;
    border: 2px solid #fff;
    position: absolute;
    right: 32px;
    /* bottom: 40px; */

    &.question__back {
        right: initial;
        background-color: rgba(0,0,0,.4);
        color: #fff;

        &:hover {
            color: rgba(0,0,0,.6);
        }

        @media (--until-tablet) {
            margin-top: 40px;
        }
    }

    &:focus {
        border: 2px solid #000;
    }

    &:hover {
        background-color: rgba(255,255,255,.5);
    }

    &[disabled],
    &[disabled]:hover,
    &[disabled]:focus {
        background-color: rgba(255,255,255,.2);
        color: rgba(0,0,0,.5);
        border-color: rgba(255,255,255,.5);
    }

    @media (--until-tablet) {
        margin-top: 20px;
        position: relative;
        width: 100%;
        right: auto;
    }
}

.question__button {
    background-color: rgba(0,0,0,.5);
    height: 94px;
    width: 310px;
    color: #fff;
    border-radius: 45px;
    padding: 0 30px;
    float: left;
    margin-right: 15px;
    margin-bottom: 20px;
    border: 2px solid transparent;
    text-transform: none;
    font-weight: normal;

    &:nth-child(3n + 3) {
        margin-right: 0;
    }

    &:hover {
        background-color: rgba(0,0,0,.7);
    }

    &:focus {
        border: 2px solid #fff;
    }

    &.is_selected {
        background-color: #fff;
        color: var(--grey);
    }

    @media (--until-desktop) {
        height: 64px;
        padding-left: 20px;
        padding-right: 20px;
        border-radius: 32px;
    }

    @media (--tablet) {
        width: 344px;

        &:nth-child(3n + 3) {
            margin-right: 15px;
        }

        &:nth-child(2n + 2) {
            margin-right: 0;
        }
    }

    @media (--until-tablet) {
        width: 100%;
        margin-right: 0;
    }
}
