.results {
    &__print,
    &__answers {
        float: left;
        clear: left;
        width: 40%;
    }

    &__print {
        background-color: rgba(0,0,0,.5);
        color: #fff;
        padding: 20px;
        font-size: 16px;
        line-height: 25px;
        border-radius: 6px;
        font-weight: normal;
        margin-top: 0;
        width: 100%;

        @media (--mobile-large) {
            margin-bottom: 30px;
        }

        @media (--mobile) {
            margin-bottom: 30px;
        }

        p {

            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }

    &__recommendations {
        float: right;
        clear: right;
        width: 55%;
    }

    &__list {
        margin: 0 0 20px;
        padding: 0 0 0 20px;
    }

    &__item {
        margin: 0 0 10px;
    }

    &__text {
        margin: 0 0 20px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__factsheet {
        appearance: none;
        display: block;
        transition: .25s background-color ease;
        line-height: 40px;
        border-radius: 20px;
        height: 40px;
        background-color: #fff;
        color: var(--grey);
        text-transform: uppercase;
        font-weight: bold;
        width: 240px;
        text-align: center;
        padding: 0;
        margin-top: 20px;
        border: 2px solid #fff;
        float: left;
        clear: both;
        text-shadow: none;

        &:focus {
            border: 2px solid #000;
            outline: 0;
        }

        &:hover {
            background-color: rgba(255,255,255,.5);
        }

        @media (--until-tablet) {
            margin-top: 20px;
        }
    }

    &__row {
        display: flex;
        justify-content: space-between;
        margin-bottom: 40px;

        @media (--until-tablet) {
            flex-direction: column;
        }
    }

    &__left,
    &__right {
        flex-basis: 47%;
        margin-bottom: 0;

        @media (--until-tablet) {
            flex-basis: 100%;
        }
    }

    a {
        transition: .4s ease opacity;

        &:hover {
            opacity: .5;
        }
    }

    ul {
        padding-left: 17.5px;
    }
}

.highlight {
    color: var(--orange);
}
