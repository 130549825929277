.trigger-size {
    width: 0;
    height: 0;
    display: none;

    &.mobile {
        @media (--mobile) {
            display: block;
        }
    }

    &.mobile-large {
        @media (--mobile-large) {
            display: block;
        }
    }

    &.tablet {
        @media (--tablet) {
            display: block;
        }
    }

    &.desktop {
        @media (--desktop) {
            display: block;
        }
    }

    &.desktop-wide {
        @media (--desktop-wide) {
            display: block;
        }
    }
}
