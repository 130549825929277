body {
    font-family: var(--font-sans);
    font-size: var(--font-size);
    font-weight: normal;
    line-height: var(--line-height-base);
    color: #fff;
    /* background-image: url(../img/dust.png);
    background-attachment: fixed;
    background-position: 50% 0;
    background-repeat: no-repeat;
    background-size: 100% auto;
    */
    background-color: var(--blue);
    background-blend-mode: screen;
    transition: 3s background-color ease;

    @media (--until-tablet) {
        background-size: 200% auto;
    }
}

/* Alternative background colours are in _icons-backgrounds */

main {
    .quiz & {
        padding-top: 30px;
    }
}

p {
    margin-bottom: 20px;
}

h1,
h2,
h3,
h4 {
    font-family: var(--font-sans);
    font-weight: bold;
    text-transform: uppercase;
}

h1,
h2 {
    max-width: calc(340 / 960 * 100%);

    @media (--until-tablet) {
        max-width: 100%;
    }
}

h1 {
    font-size: var(--font-size-h1);
    margin-top: 0;
    margin-bottom: 180px;
    line-height: calc(70 / 60 * 100%);

    .landing & {
        max-width: calc(544 / 960 * 100%);
    }

    .ar & {
        margin-bottom: 280px;
    }

    @media (--tablet) {
        font-size: 50px;
        margin-bottom: 140px;
    }

    @media (--until-tablet) {
        margin-bottom: 38px;
        font-size: 30px;

        .landing & {
            max-width: 100%;
        }

        .ar & {
            margin-bottom: 60px;
        }
    }
}

h2 {
    margin-top: 0;
    margin-bottom: 38px;
    line-height: calc(59 / 44 * 100%);
    font-weight: normal;
    font-size: var(--font-size-h2);

    @media (--tablet) {
        font-size: 38px;
        margin-bottom: 28px;
    }
}

h3 {
    margin-top: 0;
    margin-bottom: 15px;
    font-size: var(--font-size-h3);
    line-height: 1.2;
}

h4 {
    font-size: var(--font-size-h4);
}

@media (--until-tablet) {
    h1 {
        font-size: 32px;
    }

    h2 {
        font-size: 24px;
    }

    h3 {
        font-size: 18px;
    }

    h4 {
        font-size: 14px;
    }
}

a {
    text-decoration: none;
    color: #fff;
}

a,
button,
input,
select,
.button,
textarea,
[type='checkbox'] + label,
[type='radio'] + label,
label {
    &:focus {
        outline: 3px inset var(--accessibility);
    }

    .no-focus & {
        outline: 0;
    }
}

.old-browser {
    padding: 20px;
    background-color: #fff;
    color: #000;
    font-size: 16px;
    line-height: 19px;
    position: fixed;
    top: 200px;
    left: 50%;
    margin-left: -180px;
    width: 460px;
    border: 2px solid #f00;
    display: none;

    p {
        margin: 0 0 30px;

        &:last-child {
            margin: 0;
        }
    }

    small {
        display: block;
        margin-top: 10px;
        line-height: 12px;
        font-size: 12px;
    }

    &.visible {
        display: block;
    }
}

.old-browser-accept {
    border: 2px solid #000;
    padding: 10px 20px;
    background-color: #000;
    color: #fff;
    text-align: center;
    font-size: 16px;
    line-height: 16px;
    display: inline-block;

    &:hover,
    &:focus {
        background-color: #fff;
        color: #000;
    }
}

.contain {
    max-width: 1024px;
    padding-left: 32px;
    padding-right: 32px;
    margin: 0 auto;
    position: relative;
    clear: fix;

    @media (--until-desktop) {
        padding-left: 20px;
        padding-right: 20px;
    }
}

body {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    min-height: 100vh;
}

.site-header,
.site-footer {
    flex: none;
}

main {
    flex: 1 0 auto;
}
