.progress__pre-title {
    text-transform: uppercase;
    margin-bottom: 10px;
    display: none;
}

.progress__title {
    font-size: 32px;
    font-weight: bold;
    line-height: calc(42 / 32 * 100%);
    text-transform: none;
    max-width: calc(650 / 960 * 100%);
    margin-top: 0;
    margin-bottom: 40px;
    white-space: pre-wrap;

    &.is_hidden {
        display: none;
    }

    &.is_visible {
        display: block;
    }

    @media (--tablet) {
        font-size: 28px;
        margin-bottom: 30px;
    }

    @media (--until-desktop) {
        max-width: 100%;
        font-size: 22px;
        margin-bottom: 20px;
    }
}

progress {
    width: 100%;
    height: 10px;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, .2);
    border-radius: 7px;

    &::-webkit-progress-bar {
        background-color: #fff;
        border-radius: 7px;
    }

    &::-webkit-progress-value {
        background-color: var(--blue);
        border-radius: 7px;
    }
}
