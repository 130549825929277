.smoking-warning {
    clear: both;
    color: #000;
    border-radius: 6px;
    padding: 40px 60px 40px 145px;
    background-color: var(--orange);
    background-image: url(../img/no-smoking.png);
    background-repeat: no-repeat;
    background-position: 40px center;
    background-size: auto;

    h3 {
        margin-bottom: 5px;
    }

    p {
        margin-bottom: 0;
        font-size: 18px;
    }

    @media (--tablet) {
        padding: 20px 20px 20px 145px;

        h3 {
            font-size: 22px;
            line-height: 28px;
        }

        p {
            font-size: 16px;
        }
    }

    @media (--until-tablet) {
        padding: 20px;
        background-image: none;

        p {
            font-size: 16px;
        }
    }
}
