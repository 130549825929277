.button {
    background-color: #fff;
    color: var(--grey);
    text-transform: uppercase;
    font-weight: bold;
    min-width: 240px;
    text-align: center;
    display: block;
    transition: .5s background-color ease;
    padding: 0;
    border: 0;
    appearance: none;
    border: 2px solid #fff;

    &:focus {
        outline: 0;
        border: 2px solid #000;
    }

    &:hover {
        background-color: rgba(255,255,255,.5);
    }

    @media (--until-tablet) {
        width: 100%;
    }
}

.button-tall {
    line-height: 60px;
    border-radius: 40px;
    height: 60px;

    @media (--until-tablet) {
        min-height: 60px;
        line-height: 18px;
        height: auto;
        padding: 20px 30px;
        font-size: 14px;
    }
}

.button-short {
    line-height: 40px;
    border-radius: 20px;
    height: 40px;
}
