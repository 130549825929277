.factsheet-menu {
    padding-left: 0;
    font-size: 18px;

    li {
        list-style: none;
        text-transform: uppercase;

        a {
            width: 100%;
            padding: 15px 0;
            display: block;
            position: relative;
            transition: .5s color ease;
            color: #fff;

            &::after {
                content: '';
                display: block;
                width: 0;
                height: 0;
                border-top: 5px solid transparent;
                border-bottom: 5px solid transparent;
                border-left: 8px solid #fff;
                position: absolute;
                right: 0;
                top: 50%;
                transform: translate(0,-50%);
                transition: .5s border-left-color ease;
            }

            &:hover {
                color: rgba(255,255,255,.6);

                &::after {
                    border-left-color: rgba(255,255,255,.6);
                }
            }
        }

        &:not(:last-of-type) {
            border-bottom: 1px solid #fff;
        }
    }

    @media (--until-tablet) {
        margin-bottom: 0;

        li a {
            font-size: 14px;
        }
    }
}
