.tab-group-block {
    clear: fix;
    padding-bottom: 50px;

    @media (--until-tablet) {
        padding-bottom: 0;
    }
}

.tab-group-buttons {
    width: calc(360 / 960 * 100%);
    float: left;
    clear: both;

    @media (--until-tablet) {
        width: 100%;
        float: none;
        clear: both;
        margin-bottom: 40px;
    }
}

.tab-group-button {
    background: linear-gradient(to right, rgba(255,255,255,.01) 0%, rgba(255,255,255,.1) 100%);
    min-height: 56px;
    line-height: 25px;
    padding: 10px 35px 10px 0;
    border: 0;
    display: block;
    width: 100%;
    text-align: left;
    text-transform: uppercase;
    color: #fff;
    font-size: 16px;
    margin-bottom: 5px;
    position: relative;

    &::after {
        content: '';
        display: block;
        width: 10px;
        height: 56px;
        position: absolute;
        right: 15px;
        top: 50%;
        margin-top: -28px;
        background-image: url(../img/chevron-right.png);
        background-repeat: no-repeat;
        background-size: 100% auto;
        background-position: 50% 50%;
    }

    &:hover {
        background: linear-gradient(to right,  rgba(255,255,255,.01) 0%, rgba(255,255,255,.2) 100%);

        &::after {
            border-left-color: rgba(255,255,255,.2);
        }
    }

    &.active,
    &:focus {
        background: linear-gradient(to right,  rgba(255,255,255,.01) 0%, rgba(255,255,255,.3) 100%);
        outline: 0;

        &::after {
            border-left-color: rgba(255,255,255,.3);
        }
    }
}

.tab-group-content {
    float: right;
    width: calc(544 / 960 * 100%);
    overflow: hidden;

    @media (--until-tablet) {
        width: 100%;
        float: none;
        clear: both;
    }
}

.tab-group-text {
    overflow: hidden;
    transition: .5s height ease, .5s visibility;

    &[aria-hidden='true'] {
        visibility: hidden;
    }

    &[aria-hidden='false'] {
        visibility: visible;
    }

    .section-title {
        display: none;
    }
}
