.form-elem-row {
    clear: fix;
}

label {
    display: block;
    margin-bottom: 10px;
    font-size: 18px;
}

.textarea-contain,
.radio-contain,
.checkbox-contain {
    margin-top: 40px;
    clear: both;

    &:only-child,
    &:first-child {
        margin-top: 0;
    }

    @media (--until-desktop) {
        margin-top: 20px;
    }
}

.textarea-contain {
    textarea {
        width: calc(690 / 960 * 100%);
        border: 2px solid #fff;
        background-color: #fff;
        height: 116px;
        border-radius: 2px;
        padding: 15px 20px;
        transition: .5s color ease, .5s border ease;

        &::placeholder {
            color: #000;
            transition: .5s color ease;
        }

        &:focus {
            outline: 0;
            border: 2px solid #000;

            &::placeholder {
                color: #fff;
            }
        }
    }

    @media (--until-tablet) {
        textarea {
            width: 100%;
        }
    }
}

.number-contain {
    float: left;
    width: 62px;
    margin-right: 30px;
    padding: 0;

    input {
        text-align: center;
        padding: 0;
        appearance: textfield;
    }
}

input {
    width: 100%;
    border: 2px solid #fff;
    background-color: #fff;
    border-radius: 2px;
    padding: 0 20px;
    line-height: 46px;
    height: 50px;

    &:focus {
        border: 2px solid #000;
        outline: 0;
    }
}

::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
    appearance: none;
}

.select-contain {
    float: left;
    width: 300px;
    margin-right: 30px;

    &.select-wide {
        width: calc(100% - 422px);
        margin-right: 0;
    }

    @media (--until-tablet) {
        margin-bottom: 20px;

        &.select-wide {
            width: 100%;
            clear: both;
            float: none;
        }
    }

    @media (--mobile) {
        margin-top: 20px;
        width: 100%;
    }
}

select::-ms-expand {
    display: none;
}

.radio-contain,
.checkbox-contain {
    display: flex;
    flex-wrap: wrap;

    p {
        margin: 0 0 10px;
        font-size: 18px;
        flex: 0 0 100%;
    }
}

.select-wrapper {
    position: relative;
    height: 50px;
    background-color: #fff;
    border-radius: 2px;

    &::before,
    &::after {
        content: '';
        display: block;
        position: absolute;
        pointer-events: none;
    }

    &::before {
        top: 2px;
        right: 2px;
        width: 35px;
        height: 46px;
        background-color: #fff;
    }

    &::after {
        top: 20px;
        right: 17px;
        width: 0;
        height: 0;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-top: 10px solid #000;
    }

    select {
        appearance: textfield;
        background: transparent;
        border: 0;
        width: 100%;
        height: 50px;
        line-height: 50px;
        border-radius: 2px;
        padding: 0 20px;
        border: 2px solid #fff;

        &:focus {
            outline: 0;
            border: 2px solid #000;
        }
    }
}
